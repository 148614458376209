import React from "react";

const LoaderAnimate = () => {
  return (
    <>
      <div id="loader"><div className='lds-dual-ring inner-loader'></div></div>
    </>
  );
};

export default LoaderAnimate;
