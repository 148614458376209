import { createContext, useState } from "react";
import LoaderAnimate from "../../shared/components/loader/LoaderAnimate";

export const LoaderContext = createContext(null);

export const LoaderProvider = ({ children }) => {
    const [loader, setLoader] = useState(false);

    return <LoaderContext.Provider value={{ loader, setLoader }}>
        {children}
        {loader && <LoaderAnimate />}
    </LoaderContext.Provider>;
};