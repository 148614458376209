import { Container, Row, Col, Button } from 'react-bootstrap';

function ErrorPage() {
    return (
        <div className="error-page-wrapper">
            <Container>
                <Row className="justify-content-center">
                    <Col md={12} className="text-center">
                        <div className="error-content">
                            <div className="error-icon">
                            </div>
                            <div className="error-message">
                                Something went wrong on our end
                            </div>
                            <p className="error-details mb-4">
                                We are experiencing some technical difficulties. 
                                Please try again later or contact site administrator if the problem persists.
                            </p>
                            <div className="action-buttons">
                                <Button 
                                    variant="primary" 
                                    href="/" 
                                    className="return-home-btn"
                                >
                                    Return to Homepage
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ErrorPage;